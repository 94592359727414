
















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ProjectResource } from '@/models/projects/ProjectResource'
import { FlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'
import { FlexibleDocumentMetaFieldResource } from '@/models/flexibleDocument/FlexibleDocumentMetaFieldResource'
import {convertToDate, formatDate} from '@/helpers/formatDate'

@Component
export default class FlexibleDocumentDetails extends Vue {

  @Prop()
  private project!: ProjectResource

  @Prop()
  private document!: FlexibleDocumentResource

  public projectAllMetaFields: any = null
  public documentMetaFields: any = null

  private created() {
    const fields: any[] = []

    this.project.metaFields?.forEach(section => {
      section.fields.forEach(field => fields.push(field))
    })
    this.projectAllMetaFields = fields
    this.refreshDocumentMetaFields()
  }
  
  private refreshDocumentMetaFields() {
    this.documentMetaFields = JSON.parse(JSON.stringify(this.document.meta))
  }

  @Watch('document.meta')
    private onRefresh(val: boolean) {      
      this.refreshDocumentMetaFields()
    }

  public getFieldValue(field: FlexibleDocumentMetaFieldResource) {
    let values: any = null

    this.projectAllMetaFields.forEach((item: any) => {
      if (field.key === item.key) {
        const selectedValues = this.documentMetaFields[item.key]

        if (field.type === 'input') values = selectedValues
        if (field.type === 'date') {          
          values = typeof selectedValues === 'string' && selectedValues.length ? formatDate(convertToDate(selectedValues)) : ''
        }
        if (field.type === 'dropdown') {
          if (values === null) values = []

          item.options.forEach(option => {
            if (selectedValues && selectedValues.includes(option.value)) values.push(option.label)
          })
          values = values.join(', ')
        }
      }
    })
    return values
  }

  private getValue(field: FlexibleDocumentMetaFieldResource): string {    
    if (!this.project.meta) return '-'


    const value = this.project.meta[field.key] ?? undefined

    // If field type is date and value is a string format it to a date and format it back to a formatted date string
    if (field.type === 'date') {
      return typeof value === 'string' ? formatDate(convertToDate(value)) : '-'
      // Dropdown value are either arrays or empty values
      // If array join them
    } else if (field.type === 'dropdown') {
      return Array.isArray(value) ? value.join(', ') : '-'
    } else {
      return typeof value === 'string' ? value : '-'
    }
  }


}
